<template>
  <div class="container">
    <v-card
      v-for="(order, i) in orders"
      :key="i"
      class="elevation-2 d-flex justify-space-around align-center mb-3"
      
    >
      <div class="ml-2" @click="fetchOrderItems(order)" style="cursor:pointer">
        <div class="caption captitalize">
          {{ dateFromTimestam(order.timestamp) }}
        </div>
        <div class="caption captitalize">{{ order.user }}</div>
        <div class="caption captitalize">{{ order.total }} €</div>
        
      </div>
      <v-btn text="" fab @click="downloadCommandeExcel(order)">
        <v-icon large color="green">mdi-file-excel</v-icon>
      </v-btn>
      <div>
        <v-btn text color="red" @click="deleteOrder(order)"><v-icon>mdi-delete</v-icon></v-btn>
      </div>
    </v-card>
    <v-card v-if="isAdminConnected" class="mt-5 blue" dark  @click="toRecapPeriode()"
      ><v-card-title>Recap période</v-card-title></v-card
    >
    <v-card v-if="isAdminConnected"  class="mt-5 green" dark @click="toRecapPeriodeExcel()"
      ><v-card-title>Recap période Excel</v-card-title></v-card
    >
    <v-dialog v-model="showDetailsCommandeDialog">
      <DetailsCommande
        :orderItems="orderItems"
        :client="client"
        v-if="orderItems != null"
      />
    </v-dialog>
  </div>
</template>

<script>
//import axios from 'axios'
import DetailsCommande from "../components/DetailsCommande";
import { mapGetters, mapActions, mapMutations } from "vuex";
import axios from 'axios'
import FileDownload from "js-file-download";
export default {
  props: ["periode"],
  data: () => ({
    orderItems: null,
    client: null,
  }),
  computed: {
    ...mapGetters(["apiURL", "orders", "detailsCommandeDialogue", "port", 'isAdminConnected', 'globalCmdType']),
    showDetailsCommandeDialog: {
      get: function () {
        return this.detailsCommandeDialogue;
      },
      set: function (bool) {
        this.setDetailsCommandeDialogue(bool);
      },
    },
  },
  components: {
    DetailsCommande,
  },
  watch: {
    detailsCommandeDialogue(maj) {
      if (!maj) {
        this.orderItems = null;
        this.client = null;
      }
    },
  },
  methods: {
    dateFromTimestam(timestamp) {
      var dateFromT = new Date(timestamp);
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var date = dateFromT.toLocaleDateString("fr-FR", options);
      return date;
    },
    localSetOrderVu(order) {
      confirm("Cette commande sera considérée comme traitée") &&
        this.setOrderVu(order);
    },
    ...mapActions(["setOrderVu", 'deleteOrder']),
    ...mapMutations(["setDetailsCommandeDialogue"]),
    fetchOrderItems(order) {
      //window.open('http://localhost:'+this.port+'/#/commandesDetails/'+order.id)
      let routeData = this.$router.resolve({
        name: "CommandesDetails",
        params: { orderID: order.id },
        query: { cmdType: this.globalCmdType }
      });
      window.open(routeData.href, "_blank");
    },
    toRecapPeriode() {
      let routeData = this.$router.resolve({
        name: "RecapitulatifPeriode",
        query: {
          fromTimestamp: new Date(this.periode.from_picker).getTime(),
          toTimestamp: new Date(this.periode.to_picker).setHours(23),
          cmdType: this.globalCmdType
        },
      });
      window.open(routeData.href, "_blank");
      //window.open('http://localhost:'+this.port+'/#/recapitulatifPeriode/'+new Date(this.periode.from_picker).getTime() + '/' + new Date(this.periode.to_picker).setHours(23))
    },
    toRecapPeriodeExcel() {
      //console.log('torecapperiode')
      axios({
        url: `${this.apiURL}/admin/orderWithItemsExcelBetween/${new Date(this.periode.from_picker).getTime()}/${new Date(this.periode.to_picker).setHours(23)}?cmdType=${this.globalCmdType}`,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          //this.generateMessage = 'Generated !!'
          //console.log('rep ->',response)
          FileDownload(response.data, `recap ${this.periode.from_picker} - ${this.periode.from_picker}.xlsx`);
        })
        .catch((err) => {
          console.log(err);
          this.generateMessage = "something went wrong !!";
        });
    },
    async downloadCommandeExcel(order) {
      axios({
        url: `${this.apiURL}/orders/download_excel_bon_de_commande/${order.id}`,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          //this.generateMessage = 'Generated !!'
          //console.log('rep ->',response)
          FileDownload(response.data, `Cmd ${new Date(order.timestamp).toLocaleDateString()} ${order.client || ''}.xlsx`);
        })
        .catch((err) => {
          console.log(err);
          this.generateMessage = "something went wrong !!";
        });
    },
  },
};
</script>

<style scoped>
.captitalize {
  text-transform: uppercase;
}
.container {
  /*width: 550px;*/
}
</style>